var site = {
	
	init: function(){	
		this.mainurl=location.protocol + "//" + location.host+"/admin/"; 
		site.calendaractions();
		site.btnactions();
		site.commonactions();
		site.multiactions();
		site.managerreport();
	},
	
	
	loader: function(mode)
	{
		var ld=$("#loader_holder")
		if(mode=="hide")
		{
			ld.removeClass("shown");
			}else
		{
			ld.addClass("shown");
		}
	},
	
	multiactions: function()
	{
		//A few calls are to detect multi account
		if(jQuery("#changedealership").length>0)
		{
			$("body").on("click","#changedealership",function(e) {
				//Show, 
				jQuery("#hdealershipName").addClass("hidden");
				jQuery("#hdealershipmulti").addClass("hidden");
				jQuery("#changedealership").addClass("hidden");
				jQuery("#waitdealership").removeClass("hidden");
				//Ajax Get Dealership List
				var currentId=jQuery("#hdealershipId").val();
				var route=site.mainurl+"multidealership/list/ajax";
				jQuery.ajax({
						url: route,
						headers: { 'csrftoken' : '{{ csrf_token() }}' },
						type: 'POST',
						datatype: 'json',
						data: "currentId="+currentId,
						success: function(data) {
							if(data["success"]==true)
							{
									jQuery("#hdealershipName").addClass("hidden");
									jQuery("#hdealershipmulti").removeClass("hidden");
									jQuery("#changedealership").addClass("hidden");
									jQuery("#waitdealership").addClass("hidden");
									jQuery("#hdealershipmulti").html(data["data"]);
								
								}else
							{
									jQuery("#hdealershipName").removeClass("hidden");
									jQuery("#hdealershipmulti").addClass("hidden");
									jQuery("#changedealership").removeClass("hidden");
									jQuery("#waitdealership").addClass("hidden");
								
								}
						},	
            	});
				
				
				
				});
				
				$("body").on("change","#hdealershipmulti",function(e) {
					var selectedval=jQuery("#hdealershipmulti option:selected").val();
						if(selectedval!=jQuery("#hdealershipId").val())
						{
							//Trigger change
							document.location=site.mainurl+"multidealership/update/"+selectedval;
						}
					});
		
		}
                
                
                
                //A few calls are to detect multi account
		if(jQuery("#changeservicelocation").length>0)
		{
			$("body").on("click","#changeservicelocation",function(e) {
				//Show, 
				jQuery("#hservicelocationName").addClass("hidden");
				jQuery("#hservicelocationmulti").addClass("hidden");
				jQuery("#changeservicelocation").addClass("hidden");
				jQuery("#waitservicelocation").removeClass("hidden");
				//Ajax Get Dealership List
				var currentId=jQuery("#hservicelocationId").val();
				var route=site.mainurl+"multiservicelocation/list/ajax";
				jQuery.ajax({
						url: route,
						headers: { 'csrftoken' : '{{ csrf_token() }}' },
						type: 'POST',
						datatype: 'json',
						data: "currentId="+currentId,
						success: function(data) {
							if(data["success"]==true)
							{
									jQuery("#hservicelocationName").addClass("hidden");
									jQuery("#hservicelocationmulti").removeClass("hidden");
									jQuery("#changeservicelocation").addClass("hidden");
									jQuery("#waitservicelocation").addClass("hidden");
									jQuery("#hservicelocationmulti").html(data["data"]);
								
								}else
							{
									jQuery("#hservicelocationName").removeClass("hidden");
									jQuery("#hservicelocationmulti").addClass("hidden");
									jQuery("#changeservicelocation").removeClass("hidden");
									jQuery("#waitservicelocation").addClass("hidden");
								
								}
						},	
            	});
				
				
				
				});
				
				$("body").on("change","#hservicelocationmulti",function(e) {
					var selectedval=jQuery("#hservicelocationmulti option:selected").val();
						if(selectedval!=jQuery("#hservicelocationId").val())
						{
							//Trigger change
							document.location=site.mainurl+"multiservicelocation/update/"+selectedval;
						}
					});
		
		}
		
	},
	
	calendaractions: function ()
	{
            
             
                if(jQuery('#salesdatepickerbtn').length>0)
                {
                    //Only past dates (no future dates)
                    var FromEndDate = new Date();
                    jQuery('#salesdatepicker').datepicker({'format': 'yyyy/mm/dd', autoclose: true,  allowInputToggle: true,  endDate: FromEndDate,  });
                    
                    jQuery("#salesdatepicker").change(function(e){
                         console.log( jQuery('#salesdatepicker').val());
                         document.location=location.protocol + "//" + location.host+"/admin/sales/sales_entry_view/"+jQuery('#salesdatepicker').val();
                         return false;
                    });
                    
                    jQuery('#salesdatepickerbtn').click(function(e){
                         e.preventDefault();
                         jQuery('#salesdatepicker').focus();       
                    });
                   
                }
				
				if(jQuery('#saleslogdatepickerbtn').length>0)
                {
                    //Only past dates (no future dates)
                    var FromEndDate = new Date();
                    jQuery('#saleslogdatepicker').datepicker({'format': 'yyyy/mm', autoclose: true,  allowInputToggle: true,  endDate: FromEndDate,  });
                    
                    jQuery("#saleslogdatepicker").change(function(e){
                         console.log( jQuery('#saleslogdatepicker').val());
                         document.location=location.protocol + "//" + location.host+"/admin/sales/sales_log_view/"+jQuery('#saleslogdatepicker').val();
                         return false;
                    });
                    
                    jQuery('#saleslogdatepickerbtn').click(function(e){
                         e.preventDefault();
                         jQuery('#saleslogdatepicker').focus();       
                    });
                   
                }
				
				 if(jQuery('#servicedatepickerbtn').length>0)
                {
                    //Only past dates (no future dates)
                    var FromEndDate = new Date();
                    jQuery('#servicedatepicker').datepicker({'format': 'yyyy/mm', autoclose: true,  allowInputToggle: true,  endDate: FromEndDate,  });
                    
                    jQuery("#servicedatepicker").change(function(e){
                         console.log( jQuery('#servicedatepicker').val());
                         document.location=location.protocol + "//" + location.host+"/admin/service/service_entry_view/entry/"+jQuery('#servicedatepicker').val();
                         return false;
                    });
                    
                    jQuery('#servicedatepickerbtn').click(function(e){
                         e.preventDefault();
                         jQuery('#servicedatepicker').focus();       
                    });
                   
                }
				
				if(jQuery('#reportdatepickerbtn').length>0)
                {
					
					var FromEndDate = new Date(); //latest today
					
					jQuery("#reportdatepicker").datepicker( {
						format: "yyyy/mm",
						viewMode: "months", 
						minViewMode: "months",
						autoclose: true,  allowInputToggle: true,  endDate: FromEndDate, 
					});
					
					jQuery("#reportdatepicker").change(function(e){
                         console.log( jQuery('#reportdatepicker').val());
						 var mode=$(this).attr("data-mode");
                         document.location=location.protocol + "//" + location.host+"/admin/sales/sales_status_reports_view/"+mode+"/"+jQuery('#reportdatepicker').val();
                         return false;
                    });
					
					
					 jQuery('#reportdatepickerbtn').click(function(e){
                         e.preventDefault();
                         jQuery('#reportdatepicker').focus();       
                    });
				
				}
            
            
		var uagcald=$("#uag_sales_calendar");
		if(uagcald.length>0)
		{
			//Do actions
			var dayitem=$(".day-number.day-editable");
			dayitem.click(function(e) {
                        e.preventDefault();
				//Opposite status
				var me=$(this);
				
				doption=me.attr("data-option").split("|");
				var daystatus=1;
				if(doption[1]=="1")
				{
					daystatus=0;
				}
				var daynumber=doption[0];
				var route=site.mainurl+"sales/sales_monthly_admin_view/schedule/ajax";
				var dscript=me.attr("data-script");
				var didval=me.attr("data-idval");
				var did=me.attr("data-id");
				site.loader("shown");
				jQuery.ajax({
						url: route,
						headers: { 'csrftoken' : '{{ csrf_token() }}' },
						type: 'POST',
						datatype: 'json',
						data: "daystatus="+daystatus+"&action=admin_save&dscript="+dscript+"&"+did+"="+didval+"&daynumber="+daynumber,
						success: function(data) {
						//redirect page, re-open edit modal if is an add. if is an edit, click edit btn again.
						//if edit, stay. and show confirm
						if(didval!="0" && data["success"]==true)
						{ 
                                                    document.location=document.location;
						}else
                                                {
                                                   //Put error message above
                                                   bootbox.alert(data["message"], function(){
                                                       document.location=document.location;
                                                   })
                                                }
						
						},	
            	});
		
			 });
			 
		var btnnext=$("#btn-calendar-next");
		if(btnnext.length>0)
		{
			btnnext.click(function(e) {
                e.preventDefault();
				var me=btnnext;
                                bootbox.confirm("You are just about to create a new month.\nDo you wish to Continue?", 
                                function(result){
                                    if(result)
                                    {
                                        document.location=me.attr("href");
                                    }
                                });
            });
		}	 
			 
		}
		
		
		var uagcald=$("#uag_service_calendar");
		if(uagcald.length>0)
		{
			//Do actions
			var dayitem=$(".day-number.day-editable");
			dayitem.click(function(e) {
             	e.preventDefault();
				//Opposite status
				var me=$(this);
				
				doption=me.attr("data-option").split("|");
				var daystatus=1;
				if(doption[1]=="1")
				{
					daystatus=0;
				}
				var daynumber=doption[0];
				var route=site.mainurl+"service/service_monthly_admin_view/service/ajax";
				var dscript=me.attr("data-script");
				var didval=me.attr("data-idval");
				var did=me.attr("data-id");
				site.loader("shown");
				jQuery.ajax({
						url: route,
						headers: { 'csrftoken' : '{{ csrf_token() }}' },
						type: 'POST',
						datatype: 'json',
						data: "daystatus="+daystatus+"&action=admin_save&dscript="+dscript+"&"+did+"="+didval+"&daynumber="+daynumber,
						success: function(data) {
						//redirect page, re-open edit modal if is an add. if is an edit, click edit btn again.
						//if edit, stay. and show confirm
						if(didval!="0" && data["success"]==true)
						{ 
								document.location=document.location;
						}
						
						},	
            	});
		
			 });
			 
		var btnnext=$("#btn-calendar-next");
		if(btnnext.length>0)
		{
			btnnext.click(function(e) {
                e.preventDefault();
				var me=btnnext;
				if(confirm("You are just about to create a new month.\nDo you wish to Continue?"))
				{
					document.location=me.attr("href");
				}
            });
		}	 
			 
		}
		
		
		
	
	},
	
	btnactions: function ()
	{
		
		console.log("site initialized");
		
		$("body").on("click",".add_employee_do",function(e) {
			e.preventDefault();
			//Add record to List
			var me=$(this);
			var dtoken=me.attr("data-token");
			var employeeId=me.attr("data-employeeId");
			var dmode=me.attr("data-mode");
			
			switch(dmode)
			{
			case "add_people":
			var relationId=me.attr("data-salesGroupMonthId");
			var dscript="schedule_sales_people_add";
			break;
			case "add_managers":
			var relationId=me.attr("data-dealershipMonthId");
			var dscript="schedule_sales_managers_add";
			break;
			}
			
			var daction="save";
			
			site.loader("shown");	
			$(".perror","#form-modal").remove();
			//Ajax call to create the relationship
			route=site.mainurl+"sales/sales_monthly_admin_view/schedule/ajax";
			method="POST";
			jQuery.ajax({
				url: route,
				type: method,
				headers: { 'csrftoken' : '{{ csrf_token() }}' },
				datatype: 'json',
				data: '_method=POST&employeeId='+employeeId+'&relationId='+relationId+'&token='+dtoken+'&dscript='+dscript+'&action=admin_'+daction,
				success: function(data) {
					if(data["success"]==true)
					{
						$("#table_sales_persons tbody").append(data["data"]);
						me.remove();
						
					}else
					{ //Error msg
						$("#innercontent").before("<div class='alert bg-warning perror'>"+data["message"]+"</div>");
					}
					site.loader("hide");
				}
				
			});
			
			
			
		});
		
		
		$("body").on("click",".btn-confirm",function(e) {
			e.preventDefault();
			var me = $(this);
			 bootbox.confirm("Are you sure?", 
                         function(result){
                             if(result)
                             {
				if($("#autoform").length>0)
				{
					$("#autoform").submit();
				}else
				{
					me.closest("form").submit();
				}
                             }
			 });
		});
                    
        $("body").on("click",".btn-modalconfirm",function(e) {
			e.preventDefault();
			var me = $(this);
                        var dscript=me.attr("data-script");
                        
                        switch(dscript)
                        {
                            case "sales_entry_view":
                                bootbox.confirm("Day has already been marked as complete. Are you sure you want to continue adding this sale?", 
                                    function(result){
                                        if(result)
                                        {
                                         //Update action from button, and run click
                                         me.removeClass("btn-modalconfirm").addClass("btn-action");
                                         me.trigger("click");
                                         me.removeClass("btn-action").addClass("btn-modalconfirm");
                                        }
                                    });
                            
                            break;
                            //more if I need
                             default:
                                bootbox.confirm("Are you sure?", 
                                    function(result){
                                        if(result)
                                        {
                                            if($("#autoform").length>0)
                                            {
                                            $("#autoform").submit();
                                            }else
                                            {
                                            me.closest("form").submit();
                                            }
                                        }
                                });
                            
                            break;
                           
                        }
			
		});
                
		
		$("body").on("click",".cancel-btn",function(e) {
            console.log("cancelled");
		});
		
		$("body").on("click",".btn-action",function(e) {
            e.preventDefault();
			var elem=$(this);
			var modalelem=$("#form-modal");
			var modalbody=modalelem.find(".modal-body");
			var modalfooter=modalelem.find(".modal-footer");
			var daction=elem.attr("data-action");
			var dscript=elem.attr("data-script");
			var did=elem.attr("data-id");
			var didval=elem.attr("data-idval");
        	var dmodal=elem.attr("data-modal");
			var dtoken='';
			var dcrud=0;
			var droute=dscript; //By default the route will be the script code (it can be overwritten if defined)
			var doption="";
			if(elem.attr("data-option"))
			{
				doption=elem.attr("data-option");
			}
			if(elem.attr("data-crud"))
			{
				dcrud=elem.attr("data-crud");
			}
			
			if(elem.attr("data-route"))
			{
				droute=elem.attr("data-route");
			}
			
			if(elem.attr("data-token"))
			{
				dtoken=elem.attr("data-token");
			}
			
			modalbody.hide();
			if(dmodal=="1")
			{
			modalelem.find("#myModalLabel").html(elem.attr("data-modal-title"));
			modalbody.html("<p class='blue'><i class='fa fa-spinner fa-spin fa-fw '></i>Please wait...</p><div class='clear clearfix'>&nbsp;</div>");
	
			if(modalelem.attr("data-modidval")==didval && modalelem.attr("data-moddscript")==dscript  && modalelem.attr("data-modoption")==doption )
			{
				//remove old content
				modalbody.find("*").remove();
				modalfooter.find("*").remove();
				console.log("main modal content removed 521");
				modalelem.attr('data-route','');
				modalelem.attr('data-crud','0');
				modalelem.attr('data-modid','');
				modalelem.attr('data-modidval','0');
				modalelem.attr('data-moddscript','');
				modalelem.attr('data-modoption','0');
				modalelem.attr('data-moddtoken','');
				//Do not need to show again the modal . Content will just refresh.
				
			}else
			{
			modalelem.modal('show');
			}
			
			modalelem.attr("data-modroute",droute);
			modalelem.attr("data-modcrud",dcrud);
			modalelem.attr("data-modid",did);
			modalelem.attr("data-modidval",didval);
			modalelem.attr("data-moddscript",dscript);
			modalelem.attr("data-modoption",doption);
			modalelem.attr('data-moddtoken',dtoken);
			}
			
			modalelem.on('hidden.bs.modal', function () {
			// do somethingâ€¦ //Unbind events (remove content,and that should unbind too)
				//$(".btn-subaction").unbind("click");
				//Check if exists a value in the form with clickafterclose
				var afterclose="";
				if($("#clickafterclose").length>0)
				{
					afterclose=$("#clickafterclose").val();
				}
				modalelem.attr('data-modroute','');
				modalelem.attr('data-modcrud','0');
				modalelem.attr('data-modidval','0');
				modalelem.attr('data-modid','');
				modalelem.attr('data-moddscript','');
				modalelem.attr('data-modoption','0');
				modalelem.attr('data-moddtoken','');
				modalbody.find("*").remove();
				modalfooter.find("*").remove();
				console.log("main modal content removed 562");
				if(afterclose!="")
				{
					//Click element
					$(afterclose).trigger("click");
				}
				
			})
			
			
			//Define Routes. *notice also defined in the Butons actions script
			var method="POST";
			
			var route="";
			switch(dscript)
			{
			case "schedule_day_status":
			case "schedule_sales_people_add":
			case "schedule_sales_managers_add":
			case "sales_entry_sumbit_day":
			route=site.mainurl+"sales/sales_monthly_admin_view/schedule/ajax";
			break;
			}
			
			if(droute!="" && droute!=dscript)
			{
				//Use route
				route=site.mainurl+""+droute;
			}
			
			var extramethodparm='';
			if(dcrud==1)
			{
				switch(daction)
				{ //URL to present the form in the screen 
				case "edit":
					//#nerds/{id}/edit
					method="GET";
					route=site.mainurl+""+droute+"/"+didval+"/edit";
				break;
				case "add":
					//#nerds/create 
					var method="GET";
					route=site.mainurl+""+droute+"/create";
				break;
				case "view":
					var method="GET";
					route=site.mainurl+""+droute+"/"+didval;
				break;
				}
			}else{
				
					extramethodparm='_method=POST&';
				
			}
			
        	//depending on the action..do different things
			switch(daction)
			{
			case "edit":
			case "add":
			case "view":
				//Do Ajax call
				jQuery.ajax({
				url: route,
				type: method,
				headers: { 'csrftoken' : '{{ csrf_token() }}' },
				datatype: 'json',
				data: extramethodparm+'token='+dtoken+'&dscript='+dscript+'&did='+did+'&didval='+didval+'&doption='+doption+'&action=admin_'+daction,
				success: function(data) {
					console.log("Return data"+modalbody.length);
					
					if(data["success"]==true)
					{
						modalbody.html(data["data"]);
						
					}else
					{ //Error msg
						modalbody.html(data["message"]);
					}
					
					
					//Show footer buttons
					jQuery.ajax({
					url: site.mainurl+"buttons",
					type: 'GET',
					datatype: 'json',
					data: 'dscript='+dscript+'&viewmode='+daction+'&action=admin_viewbuttons',
					success: function(data) {
							//get the buttons
							modalfooter.html(data["data"]); //buttons placed. now, get the action on click of these buttons
							
							$("#adminform").addClass("form-horizontal");
							//$("#adminform").find("label").addClass("col-sm-2");
							//$("#adminform").find(".form-control").wrap('<div class="col-sm-10"></div>');
							//$("#adminform").find(".form-control-static").wrap('<div class="col-sm-10"></div>');
							
							modalbody.show();
							
							switch(dscript)
							{
								
								case "schedule_sales_people_add":
								case "schedule_sales_managers_add":
                                                                case "admin_permission_edit":
                                                                case "sales_entry_sumbit_day":
									//Use DataTable Scripts
									site.subactions(did,didval,dscript,doption,daction,dtoken);
								break;
                                                                case "salesgroup_view":
                                                                case "admin_salesgroup_edit":   
                                                                    site.gacolors();
                                                                break;
								case "sales_entry_view":
									site.deliveredpop();
									site.datepicker();
									site.minuspluscalc();
								break;
								case "users":
									site.customvalidators();
								break;
								case "inventory_entry_view":
								case "cars_entry_view":
								site.forzecost();
								site.datepicker();
								break;
								
								default:
								
								break;
							}
							
						
							
							
					} //success
					});
					
					
				},
				error: function(e) {
				//called when there is an error
				//console.log(e.message);
				}
				
				
				
				});
			
				
			break;
			
			
			}
			
		});
		
		$("body").on("click",".btn-footeraction",function(e){
			e.preventDefault();
			var subelem=$(this);
			var subaction=subelem.attr("data-action");
			
			//Notice, some of the Buttons, can be also used as PLAIN buttons. In this case we add a data-plain attribute. (no modal)
			var buttonplain=0;
			if(subelem.attr("data-plain"))
			{
				buttonplain=subelem.attr("data-plain");
			}
			
			if(buttonplain==1)
			{  //button is triggered without a modal popup  
			   //get details from button
			    var dotken=subelem.attr("data-token");
				var dscript=subelem.attr("data-script");
				var didval=subelem.attr("data-idval");
				var did=subelem.attr("data-id");
				var dtoken='';
				var dcrud=0;
				var droute=dscript; //By default the route will be the script code (it can be overwritten if defined)
				var doption="";
				if(subelem.attr("data-option"))
				{   //For many to many relationship tables, it will have the model|id of the relation
					doption=subelem.attr("data-option");
				}
				
				if(subelem.attr("data-crud"))
				{
					dcrud=subelem.attr("data-crud");
				}
				
				if(subelem.attr("data-route"))
				{
					droute=subelem.attr("data-route");
				}
				
				if(subelem.attr("data-token"))
				{
					dtoken=subelem.attr("data-token");
				}

			}else
			{ //Get details from Modal
			
				var modalelem=$("#form-modal");
				var modalbody=modalelem.find(".modal-body");
				var modalfooter=modalelem.find(".modal-footer");
				var dtoken=modalelem.attr("data-moddtoken");
				var dscript=modalelem.attr("data-moddscript");
				var didval=modalelem.attr("data-modidval");
				var did=modalelem.attr("data-modid");
				var dtoken='';
				var dcrud=0;
				var droute=dscript; //By default the route will be the script code (it can be overwritten if defined)
				var doption="";
				if(modalelem.attr("data-modoption"))
				{
					doption=modalelem.attr("data-modoption");
				}
				
				if(modalelem.attr("data-modcrud"))
				{
					dcrud=modalelem.attr("data-modcrud");
				}
				
				if(modalelem.attr("data-modroute"))
				{
					droute=modalelem.attr("data-modroute");
				}
				
				if(modalelem.attr("data-moddtoken"))
				{
					dtoken=modalelem.attr("data-moddtoken");
				}
				
				
			}	
			
			var route="";
			switch(dscript)
			{
			case "schedule_day_status":
			case "sales_entry_sumbit_day":
			route=site.mainurl+"sales/sales_monthly_admin_view/schedule/ajax";
			break;
			}
			
			
			if(droute!="" && droute!=dscript)
			{
				//Use route
				route=site.mainurl+""+droute;
			}
			
			if(dcrud==1)
			{
				if(didval!="0")
				{//Save from EDIT
				//#nerds/{id}/edit
				route=site.mainurl+""+droute+"/"+didval;
				}
				else
				{ //Save from ADD POST 	/nerds 	store 	nerds.store 
				//#nerds/create 
				route=site.mainurl+""+droute+"";
				}
			}
			
				switch(subaction)
				{							
					case "delete":
					
						bootbox.confirm("Are you sure?", 
						function(result){
							if(result)
							{    
							site.runajaxdelete(dscript,did,didval,modalelem,dcrud,route,doption,dtoken);
							}
						});

					
					break;
					case "save":
				
					
					site.loader("shown");
					subelem.addClass("disabled");
					$(".error-label").remove();
					$("#adminform").find(".required_error").removeClass("required_error")
					$("#adminform").find("[datatype='required']").addClass("required");
					$("#adminform .required").each(function(index, element) {
						var innerele=$(this);
						if(innerele.val()=="" )
						{
							innerele.addClass("required_error");
                                                        //check if parent should display the error class too
                                                        var errortarget=innerele.attr("data-errortarget");
                                                        if($(errortarget).length>0)
                                                        {
                                                           $(errortarget).addClass("required_error");
                                                        }
						}else
						{
							//Check is a nonZero element
							switch(innerele.attr("datavalidate"))
							{
							case "nozero":
							if(innerele.val()=="0")
							{
								innerele.addClass("required_error");
								if(innerele.attr("datavisualelem")!="undefined")
								{ //Apply border to the label
									$(innerele.attr("datavisualelem")).addClass("required_error");
								}	
							}
							break;
							case "multicheckbox":
								//get element group
								var  datagroup=innerele.attr("datagroup");
								if($("#adminform [datagroup='"+datagroup+"']:checked").length==0)
								{
									$(innerele.attr("datavisualelem")).addClass("required_error");
								}
								
							break;
								
							}
							
							
						}
					});
					if($("#adminform .required_error").length>0)
					{
						$("#adminform").after("<div class='clear clearfix error-label'></div><div class='bg-warning margin-top-10 error-label alert'>Please fill out all fields</div>");
						subelem.removeClass("disabled");
						site.loader("hide");
						//User must fix errors
					}else
					{
						
					
					var allgood=1;
					var errormsg='';
						
					
					if(allgood==0)
					{
						site.loader("hide");
						$("#adminform").after("<div class='clear clearfix error-label'></div><div class='bg-warning margin-top-10 error-label alert'>"+errormsg+"</div>");
						subelem.removeClass("disabled");
						site.loader("hide");
						//User must fix errors
					}else
					{
					console.log("Route"+route);
					jQuery.ajax({
						url: route,
						type: 'POST',
						datatype: 'json',
						data: $('#adminform').serialize() + "&action=admin_save&dscript="+dscript+"&did="+did+"&didval="+didval,
						success: function(data) {
						//redirect page, re-open edit modal if is an add. if is an edit, click edit btn again.
						//if edit, stay. and show confirm
						if(didval=="0")
						{ //Add. Refresh page
							if(data["success"]==false)
							{
								site.loader("hide");
								subelem.removeClass("disabled");
								$("#adminform").after("<div class='clear clearfix error-label'></div><div class='bg-warning margin-top-10 error-label alert'>"+data["message"]+"</div>");														
								//document.location=document.location;
								//reenable fields
							}else
							{
								$("#adminform").after("<div class='clear clearfix error-label'></div><div class='bg-success margin-top-10 error-label alert'>The information has been updated.</div>");														
								document.location=document.location;
								
							}
							
							//if add close
						}else
						{ //edit 
							if(data["success"]==false)
							{
							site.loader("hide");	
							subelem.removeClass("disabled");	
							$("#adminform").after("<div class='clear clearfix error-label'></div><div class='bg-warning margin-top-10 error-label alert'>"+data["message"]+"</div>");														
							//document.location=document.location;
							}else
							{
								$("#adminform").after("<div class='clear clearfix error-label'></div><div class='bg-success margin-top-10 error-label alert'>The information has been updated.</div>");
								subelem.removeClass("disabled");
								//Refresh By Click button in List
								var modalmodid=modalelem.attr('data-modid');
								modalelem.modal('hide');
								//May Want to put data in the table for specific actions
                                                                switch(dscript)
                                                                {
                                                                    case "admin_permission_edit":
                                                                     //put data in the table
                                                                     $(".permissions[data-rel='"+didval+"']").html(data["data"]);
                                                                     site.loader("hide");
                                                                    break;
                                                                    default:
                                                                        if('refresh' in data)
                                                                        {
                                                                            console.log(data["refresh"]);
                                                                            if(data["refresh"]==true)
                                                                            {
                                                                            document.location=document.location;	
                                                                            }
                                                                            else
                                                                            {
                                                                            site.loader("hide");
                                                                            }
                                                                        }else
                                                                        {
                                                                        site.loader("hide");
                                                                        }
                                                                    break;
                                                                   
                                                                }
                                                                
								
							}
							
						}
						
						}
					});
					
					}
						
					}
					
					break;
					
				}
			
		});
  	
	},
	
	runajaxdelete: function(dscript,did,didval,modalelem,dcrud,route,doption,dtoken)
	{ //if Crud, I have to define the right route, if not crud, just use the route with the did. for customization I can use dscript  too
			
			//Crud will use the DELETE method (set in the parent form)
			site.loader("show");
			jQuery.ajax({
				url: route,
				type: 'POST',
				datatype: 'json',
				headers: { 'csrftoken' : '{{ csrf_token() }}' },
				data: "_method=DELETE&_token="+dtoken+"&doption="+doption+"&dscript="+dscript+"&did="+did+"&didval="+didval,
				success: function(data) {
					if(data["success"]==true)
					{
						if(data["refresh"]==true)
						{
						document.location=document.location;	
						}
					}else
					{
						
                                                bootbox.alert("Sorry, you can't delete.<br>"+data["message"], function(){
                                                       //Remove loader
                                                        site.loader("hide");
                                                   })
                                                
					}
				}
			});
	
	},
	
	subactions: function(did,didval,dscript,doption,daction,dtoken)
	{	
		switch(dscript)
		{
			case "schedule_sales_people_add":
			case "schedule_sales_managers_add":
				switch(daction)
				{
					case "view":
						//Each tab will be trugger from here
						//datatable code
						$(".add_people_tab","#form-modal").click(function(e) {
                                                e.preventDefault();
							var me=$(this);
							//Get data into current holder
							var innertab=$("#innercontent");
							innertab.html("<p class='blue'><i class='fa fa-spinner fa-spin fa-fw '></i>Please wait...</p><div class='clear clearfix'>&nbsp;</div>");
							//Active tab
							me.closest("ul").find("li.active").removeClass("active");
							me.closest("li").addClass("active");
							
							route=site.mainurl+"sales/sales_monthly_admin_view/schedule/ajax";
							method="POST";
							var dmode=me.attr("data-mode");
							switch(dmode)
							{
								case "dealership":
								dscript="schedule_sales_people_add_dealership"; //Mode : dealership or mode all
								break;
								case "all":
								dscript="schedule_sales_people_add_all"; //Mode : dealership or mode all
								break;
								
								case "dealership_managers":
								dscript="schedule_sales_managers_add_dealership"; //Mode : dealership or mode all
								break;
								case "all_managers":
								dscript="schedule_sales_managers_add_all"; //Mode : dealership or mode all
								break;
								
							}
							
							daction="view";
							
							jQuery.ajax({
							url: route,
							type: method,
							headers: { 'csrftoken' : '{{ csrf_token() }}' },
							datatype: 'json',
							data: '_method=POST&token='+dtoken+'&dscript='+dscript+'&did='+did+'&didval='+didval+'&doption='+doption+'&action=admin_'+daction,
							success: function(data) {
							
								if(data["success"]==true)
								{
								innertab.html(data["data"]);
								}else
								{ //Error msg
								innertab.html(data["message"]);
								}
								
								$("#example1").DataTable();
								
							}
							});
							
							
                        });
						
						switch(dscript)
						{
						case "schedule_sales_people_add":
						$(".add_people_tab[data-mode='dealership']","#form-modal").trigger("click"); //load first tab
						break;
						case "schedule_sales_managers_add":
						$(".add_people_tab[data-mode='dealership_managers']","#form-modal").trigger("click"); //load first tab
						break;
						}
						
					break;
				}
			break;	
                        case "admin_permission_edit":
                            if($(".perm_default").length>0)
                            {
                                $(".perm_default").click(function(e){
                                e.preventDefault();
                                var me=$(this);
                                var permskey=me.attr("data-key");
                                var perms=me.attr("data-perms");

                                switch(permskey)
                                {
                                case "perm_clear":
                                site.clearAllPerms();
                                break;
                                default:
                                site.addPerms(perms);    
                                break;
                                }

                                });

                            }
                        break;
                        
                        case "sales_entry_sumbit_day":
                          if($("input#closingPercentage").length>0)
                            {
                                //On hit enter, submit the form
                            $("input#closingPercentage").keypress(function (e) {
                                if (e.which == 13) {
                                    //submit action save form
                                    e.preventDefault();
                                    $(".modal[data-moddscript='"+dscript+"'] .btn-footeraction[data-action='save']").trigger("click");
                                    return false;
                                }
                            });
                            }
                         
                        break;
                        
		}
	
	},
        gacolors: function()
        {
          $(".agcolorpicker .gacol").click(function(e) {
              e.preventDefault;
              var me=$(this);
              var mecolor=me.attr("data-color");
              $(".agcolorpicker .gacol.selected").removeClass("selected");
              me.addClass("selected");
              $(".agcolor").val(mecolor);
          });
        },	
        datepicker: function()
	{
		$('#datepicker').datepicker({'format': 'yyyy-mm-dd'})
	},
	
	minuspluscalc: function()
	{
		//field calculation
		jQuery("#front").focusout(function(){
				//REPLACE . WITH ""
				jQuery("#front").val(jQuery("#front").val().replace(",", "")); 
				jQuery("#front").val(parseInt(jQuery("#front").val()) || 0);
				jQuery("#journal").val(jQuery("#journal").val().replace(",", ""));
				jQuery("#journal").val(parseInt(jQuery("#journal").val()) || 0);
				
				if (jQuery("#journal").val() == 0) {
				    $("#plusMinus").val(0);
				} else {
				    $("#plusMinus").val(parseFloat(parseFloat(jQuery("#journal").val())-parseFloat(jQuery("#front").val() || 0)));
				}
				$("#plusMinusHolder").html($("#plusMinus").val());
		
		});
		
		jQuery("#back").focusout(function(){
				jQuery("#back").val(jQuery("#back").val().replace(",", "")); 
				jQuery("#back").val(parseInt(jQuery("#back").val()) || 0);
				jQuery("#journalBack").val(jQuery("#journalBack").val().replace(",", ""));
				jQuery("#journalBack").val(parseInt(jQuery("#journalBack").val()) || 0);
				
				if (jQuery("#journalBack").val() == 0) {
				    $("#plusMinusBack").val(0);
				} else {
				    $("#plusMinusBack").val(parseFloat(parseFloat(jQuery("#journalBack").val())-parseFloat(jQuery("#back").val() || 0)));
				}
				$("#plusMinusBackHolder").html($("#plusMinusBack").val());
		});
		
		jQuery("#journal").focusout(function(){
		        jQuery("#front").val(jQuery("#front").val().replace(",", "")); 
				jQuery("#front").val(parseInt(jQuery("#front").val()) || 0);
				jQuery("#journal").val(jQuery("#journal").val().replace(",", ""));
				jQuery("#journal").val(parseInt(jQuery("#journal").val()) || 0);
				
				if (jQuery("#journal").val() == 0) {
				    $("#plusMinus").val(0);
				} else {
				    $("#plusMinus").val(parseFloat(parseFloat(jQuery("#journal").val())-parseFloat(jQuery("#front").val() || 0)));
				}
				$("#plusMinusHolder").html($("#plusMinus").val());
		});
		
		jQuery("#journalBack").focusout(function(){
				jQuery("#back").val(jQuery("#back").val().replace(",", "")); 
				jQuery("#back").val(parseInt(jQuery("#back").val()) || 0);
				jQuery("#journalBack").val(jQuery("#journalBack").val().replace(",", ""));
				jQuery("#journalBack").val(parseInt(jQuery("#journalBack").val()) || 0);
			
				if (jQuery("#journalBack").val() == 0) {
				    $("#plusMinusBack").val(0);
				} else {
				    $("#plusMinusBack").val(parseFloat(parseFloat(jQuery("#journalBack").val())-parseFloat(jQuery("#back").val() || 0)));
				}
				$("#plusMinusBackHolder").html($("#plusMinusBack").val());
		});
		
		
		
	},
	
	forzecost: function()
	{
		
		jQuery("#cost").focusout(function(){
				
				jQuery("#cost").val(jQuery("#cost").val().replace(",", ""));
				jQuery("#cost").val(parseInt(jQuery("#cost").val()) || 0);
		});
	},
	
	deliveredpop: function()
	{   //This one is for the FORM on Add/Edit
		var deliveredfield=$("#adminform #delivered");
		var backfield=$("#adminform #back");
		deliveredfield.unbind("change");
		deliveredfield.change(function(e) {
                if($(this).val()==1)
			{
                            
                            bootbox.prompt({
                                title: "Please confirm back value:",
                                value: backfield.val(),
                                callback: function (backvalue) {
                                   if (backvalue != null) {
					backfield.val(backvalue);
                                    }
                                }
                            });
                             
			}
        });
	},
        updateDelivered: function(dbackvalue,ddeliveredvalue,dsaleid,doption)
        {
        //Run Ajax Update Call
					var route=site.mainurl+"sales/sales_monthly_admin_view/schedule/ajax";
					var dscript="sale_edit_delivered";
					var didval=dsaleid;
					var did="saleId"
					site.loader("shown");
					jQuery.ajax({
						url: route,
						headers: { 'csrftoken' : '{{ csrf_token() }}' },
						type: 'POST',
						datatype: 'json',
						data: "doption="+doption+"&back="+dbackvalue+"&delivered="+ddeliveredvalue+"&action=admin_save&dscript="+dscript+"&"+did+"="+didval,
						success: function(data) {
						//redirect page, re-open edit modal if is an add. if is an edit, click edit btn again.
						//if edit, stay. and show confirm
						if(didval!="0" && data["success"]==true)
						{ 
								document.location=document.location;
						}
						
						},	
					});
        
        },
	commonactions: function()
	{
		//For elements that are in the dom when Jquery is ready (this function is called at any times)
		
		if($("#table_sales_log").length>0)
		{
			//On Click #delivered  From the table list (notice Edit one is on the function deliveredpop)
			
			var ddeliveredfield=$("#table_sales_log .deliveredfield");
			ddeliveredfield.unbind("change");
			ddeliveredfield.change(function(e) {
			if(!$(this).hasClass('disabled') )
			{
				var updatedelivered=0;
				var dbackfieldval=$(this).attr("data-back");
				var dsaleid=$(this).attr("data-saleid");
				var doption=$(this).attr("data-option");
                                var mecheckbox=$(this);
				if(mecheckbox.is(":checked"))
				{
                                    var ddeliveredvalue=1;
                                        
                                    bootbox.prompt({
                                        title: "Please confirm back value:",
                                        value: dbackfieldval,
                                        callback: function (backvalue) {
                       
                                            if (backvalue != null) {
                                                updatedelivered = 1;
                                                site.updateDelivered(backvalue,ddeliveredvalue,dsaleid,doption);
                                            } else
                                            {
                                                 //Uncheck field
                                                updatedelivered = 0;
                                                ddeliveredvalue = 0;
                                                mecheckbox.prop('checked', false);
                                            }

                                        }
                                    });
                                        
                                        
                                        
				}else
				{
					var dbackvalue=dbackfieldval;
					var ddeliveredvalue=0;
				 	updatedelivered=1;
				}
				
				if(updatedelivered=="1")
				{
					site.updateDelivered(dbackvalue,ddeliveredvalue,dsaleid,doption);
					
				}
				
			}
			});
		
		}
                
                if($("#dataTableList").length>0)
                {
                    var mode=$("#dataTableList").attr("data-mode");
                    switch(mode)
                    {
                        case "permissions":
                            $("#dataTableList").DataTable({
                              "lengthMenu": [[25, 100, 250, -1], [25, 100, 250, "All"]],
                              "order": [[ 3, "desc" ]]
                             });
                        break;
                        //dealership_salesgroups ?
                        case "dealership_salesgroups":
                            $("#dataTableList").DataTable({
                               "lengthMenu": [[25, 100, 250,500, -1], [25, 100, 250,500, "All"]]
                            });
                        break;
                        
                        case "manager-ranking":
                            $("#dataTableList").DataTable({
                               "lengthMenu": [[25, 100, 250,500, -1], [25, 100, 250,500, "All"]],
                               "bPaginate": false,
							   "bLengthChange": false,
							   "bFilter": false,
							   "bSort":true,
							   "bInfo": false,
							   "bAutoWidth": false 
                            });
                        break;
                        
                        case "store-ranking":
                            $("#dataTableList").DataTable({
                               "lengthMenu": [[25, 100, 250,500, -1], [25, 100, 250,500, "All"]],
                               "bPaginate": false,
							   "bLengthChange": false,
							   "bFilter": false,
							   "bSort":true,
							   "bInfo": false,
							   "bAutoWidth": false 
                            });
                            
                        break;
                        
                        default:
                            $("#dataTableList").DataTable({
                               "lengthMenu": [[25, 100, 250,500, -1], [25, 100, 250,500, "All"]]
                            });
                        break;
                       
                    }
                    
                    
                   
                }
              
              
	
	},
	
	managerreport: function()
	{
		var selectedval=$("#mgrdealershiplist").value;
		$("#mgrdealershiplist").on("change",function(e) {
			var selectedval=this.value;
			var report_year = jQuery("input#report_year").val();
			var report_month = jQuery("input#report_month").val();
			//Trigger change - empty year and month
			document.location=site.mainurl+"sales/sales_status_reports_view/manager_ranking/" + report_year + "/" + report_month + "/"+selectedval;
		});
		
	},
	
	afterajaxactions: function()
	{ //For elements that are in the dom after ajax call is returned (must be called on complete)
		
	},
        clearAllPerms:function()
        {
            $(".perm:checked").each(function(index, element) {
                $(this).prop("checked",false);
                $(this).removeAttr("checked");
            });
            
        },
        addPerms:function(perms)
        {
            site.clearAllPerms();
            var permsloop=perms.split(",");
            for(i=0;i<permsloop.length;i++)
            {
                $("#"+permsloop[i]+".perm").prop("checked",true);
                $("#"+permsloop[i]+".perm").attr("checked","checked");
                
            }
        }
	

};


$(function() {	
	site.init();
});